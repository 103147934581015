import React from 'react';
import styled from 'styled-components';
import {List, Tag, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import withIsInApp from '../../withIsInApp';
import withPageEntry from '../../withPageEntry';
import {getMeetingRoomLabel} from '../../Utils/SelectOptionLabelUtil';
import DataJson from '../../../data.json';

function generateExistFirstYearsArray() {
  const firstYear = 112;
  const years = [];

  years.push(`${firstYear}`);
  const currentYear = new Date().getFullYear() - 1911;

  if (currentYear > firstYear) {
    for (let i = 0; i < currentYear - firstYear; i++) {
      years.push(`${firstYear + i + 1}`);
    }
  }

  return years;
}

function JoinList(props) {
  const {isLoadingAppToken, fromMobileApp} = props;
  const [user] = useOutlet('user');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [courses, setCourses] = React.useState(null);
  const [activities, setActivities] = React.useState(null);
  const [lectures, setLectures] = React.useState(null);
  const [meetings, setMeetings] = React.useState(null);

  const fetchMyCourses = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let {joins, lectures} = await AppActionsEx.getUserJoinRecords({
        first_years: generateExistFirstYearsArray(),
      });

      joins = joins.map((it) => {
        return {
          id: it._id.$oid,
          ...it,
        };
      });

      setCourses(
        joins.filter((it) => {
          for (const l of it.labels || []) {
            if (l.indexOf('課程') > -1) {
              return true;
            }
          }

          if (it.internal_label === '在職進修') {
            return true;
          }

          return false;
        }),
      );

      setActivities(
        joins.filter((it) => {
          for (const l of it.labels || []) {
            if (l.indexOf('活動') > -1) {
              return true;
            }
          }
          return false;
        }),
      );

      lectures = lectures.map((it) => {
        return {
          id: it._id.$oid,
          ...it,
        };
      });

      setLectures(lectures);
    } catch (err) {
      console.warn(err);
    }
    AppActions.setLoading(false);
  }, [user?.token]);

  const fetchMyMeetings = React.useCallback(async () => {
    try {
      AppActions.setLoading(false);
      const meetings_resp = await JStorage.fetchDocuments(
        'Meeting',
        {
          users: {$in: [{user: user.sub}]},
        },
        ['-created'],
        {
          //TODO: support pagination
          offset: 0,
          limit: 1000,
        },
      );

      setMeetings(meetings_resp.results);
      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppActions.setLoading(false);
    }
  }, [user?.sub]);

  React.useEffect(() => {
    if (!isLoadingAppToken) {
      fetchMyCourses();
      fetchMyMeetings();
    }
  }, [fetchMyCourses, fetchMyMeetings, isLoadingAppToken]);

  return (
    <Wrapper selectedTab={selectedTab} fromMobileApp={fromMobileApp}>
      <div className="container">
        {!fromMobileApp && <h1>參與紀錄</h1>}

        <div className="tab-header">
          <div className="item index-0" onClick={() => setSelectedTab(0)}>
            課程
          </div>
          <div className="item index-1" onClick={() => setSelectedTab(1)}>
            活動
          </div>
          <div className="item index-2" onClick={() => setSelectedTab(2)}>
            會議
          </div>
          <div className="item index-3" onClick={() => setSelectedTab(3)}>
            授課紀錄
          </div>
        </div>

        {!fromMobileApp && selectedTab !== 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 15,
            }}>
            <Button
              href={`/print-preview-joins/?type=${
                ['course', 'activity', '---', 'lecture'][selectedTab]
              }`}
              target="_blank">
              列印
            </Button>
          </div>
        )}

        <div className="tab-container">
          {selectedTab === 0 && courses && (
            <List
              bordered
              itemLayout="vertical"
              size="large"
              dataSource={[
                {
                  session: '場次時間',
                  serial_number: '編號',
                  name: '名稱',
                  tba_hours: '核給時數',
                },
                ...courses,
              ]}
              renderItem={(it, index) => {
                if (index === 0) {
                  return (
                    <List.Item className="header item" key={index}>
                      <div className="col-large">{it.session}</div>
                      <div className="col-small">{it.serial_number}</div>
                      <div className="col-large" style={{maxWidth: 250}}>
                        {it.name}
                      </div>
                      <div className="col-small">{it.tba_hours}</div>
                    </List.Item>
                  );
                }

                const isSession =
                  it.session &&
                  Object.keys(it.session).length > 0 &&
                  it.session.date;
                const sessionText = isSession
                  ? `${it.session.date} ${
                      it.session.start_time
                        ? `${it.session.start_time}-${it.session.end_time}`
                        : ``
                    }`
                  : `線上自由安排`;

                return (
                  <List.Item className="item" key={index}>
                    <div className="col-large" style={{fontWeight: '400'}}>
                      {sessionText}
                    </div>
                    <div className="col-small">{it.serial_number || '---'}</div>
                    <div className="col-large" style={{maxWidth: 250}}>
                      {it.name}
                    </div>
                    <div className="col-small">
                      <label>核給時數：</label>
                      {it.tba_hours || 0} 小時
                    </div>
                  </List.Item>
                );
              }}
            />
          )}

          {selectedTab === 1 && activities && (
            <List
              bordered
              itemLayout="vertical"
              size="large"
              dataSource={[
                {
                  session: '場次時間',
                  name: '名稱',
                  tba_hours: '核給時數',
                },
                ...activities,
              ]}
              renderItem={(it, index) => {
                if (index === 0) {
                  return (
                    <List.Item className="header item" key={index}>
                      <div className="col-large">{it.session}</div>
                      <div className="col-large" style={{maxWidth: 350}}>
                        {it.name}
                      </div>
                      <div className="col-small">{it.tba_hours}</div>
                    </List.Item>
                  );
                }

                const isSession =
                  it.session &&
                  Object.keys(it.session).length > 0 &&
                  it.session.date;
                const sessionText = isSession
                  ? `${it.session.date} ${
                      it.session.start_time
                        ? `${it.session.start_time}-${it.session.end_time}`
                        : ``
                    }`
                  : `線上自由安排`;

                return (
                  <List.Item className="item" key={index}>
                    <div className="col-large" style={{fontWeight: '400'}}>
                      {sessionText}
                    </div>
                    <div className="col-large" style={{maxWidth: 350}}>
                      {it.name}
                    </div>
                    <div className="col-small">
                      <label>核給時數：</label>
                      {it.tba_hours || 0} 小時
                    </div>
                  </List.Item>
                );
              }}
            />
          )}

          {selectedTab === 2 && meetings && (
            <List
              bordered
              itemLayout="vertical"
              size="large"
              dataSource={[
                {
                  name: '名稱',
                  date: '會議日期',
                  time: '會議時間',
                  room: '會議地點',
                },
                ...meetings,
              ]}
              renderItem={(meeting, index) => {
                if (index === 0) {
                  return (
                    <List.Item className="header item">
                      <div className="col-large">{meeting.name}</div>
                      <div className="col-small">{meeting.date}</div>
                      <div className="col">{meeting.time}</div>
                      <div className="col">{meeting.room}</div>
                    </List.Item>
                  );
                }

                return (
                  <List.Item className="item">
                    <div className="col-large">{meeting.MTNG_NAME}</div>
                    <div className="col-small">{meeting.EFF_DATE}</div>
                    <div className="col">
                      {meeting.START_TIME}-{meeting.END_TIME}
                    </div>
                    <div className="col">
                      {getMeetingRoomLabel(meeting.room, meeting.room_note)}
                    </div>
                  </List.Item>
                );
              }}
            />
          )}

          {selectedTab === 3 && lectures && (
            <List
              bordered
              itemLayout="vertical"
              size="large"
              dataSource={[
                {
                  name: '名稱',
                  labels: '分類',
                  tba_hours: '核給時數',
                },
                ...lectures,
              ]}
              renderItem={(it, index) => {
                if (index === 0) {
                  return (
                    <List.Item className="header item" key={index}>
                      <div className="col-large">{it.name}</div>
                      <div className="col">{it.labels}</div>
                      <div className="col-small">{it.tba_hours}</div>
                    </List.Item>
                  );
                }

                return (
                  <List.Item className="item" key={index}>
                    <div className="col-large">{it.name}</div>
                    <div className="col">
                      {it.labels.map((label, idx) => (
                        <Tag key={idx}>{label}</Tag>
                      ))}
                    </div>
                    <div className="col-small">
                      <label>核給時數：</label>
                      {it.tba_hours || 0} 小時
                    </div>
                  </List.Item>
                );
              }}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: ${(props) => (props.fromMobileApp ? '0px' : 'var(--topNavBarHeight)')}
    auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;
    padding: 20px 40px;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & > div.tab-header {
      display: flex;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

     
      ${({selectedTab}) =>
        `& > div.index-${selectedTab} {  border-bottom: 5px solid var(--secondColor); }`};
  }

  & > div.tab-container {
    padding-top: 20px;


    & .item {
      display: flex;
      align-items: center;


      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: start;
      }
    }

    & .header {
      font-weight: bold;
      color: #000000;
      font-size: 1rem;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .col-large {
      max-width: 200px;
      width: 100%;
      padding: 5px;
      font-weight: bold;
      font-size: 0.95rem;

      @media screen and (max-width: 768px) {
        max-width: 100%;

      }
    }

    .col {
      max-width: 100px;
      width: 100%;
      padding: 5px;

      @media screen and (max-width: 768px) {
        max-width: 100%;

      }
    }

    .col-small {
      max-width: 80px;
      width: 100%;
      padding: 5px;

      & > label {
        display: none;
      }

      @media screen and (max-width: 768px) {
        max-width: 100%;

        & > label {
          display: inline-block;
        }
      }
    }

  }
`;

const SectionContainer = styled.div`
  margin: 0 atuo;
  padding: 10px;
  text-align: center;
  white-space: pre;

  & > h2 {
    margin-bottom: 10px;
    white-space: pre-wrap;
    color: var(--secondColor);

    & > a {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
`;

export default withIsInApp(withPageEntry(JoinList));
